import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const AGTableContext = createContext();
export const useAGTableContext = () => useContext(AGTableContext);

export function AGTableProvider({ children }) {
  const [tableApi, setTableApi] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [activeRow, setActiveRow] = useState(-1);
  const [update, setUpdate] = useState(false);
  const [isActiveTab, setIsActiveTab] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMultiDelete, setOpenMultiDelete] = useState(false);
  const [openNewContract, setOpenNewContract] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openArchive, setOpenArchive] = useState(false);
  const [openMultiArchive, setOpenMultiArchive] = useState(false);
  const [openEditUser, setOpenEditUser] = useState(false);
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const [openClause, setOpenClause] = useState(false);
  const [openNewClause, setOpenNewClause] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const [openFastTrackBusinessUnit, setOpenFastTrackBusinessUnit] = useState(false);
  const [openNewFastTrackBusinessUnit, setOpenNewFastTrackBusinessUnit] = useState(false);
  const [openFastTrackEntity, setOpenFastTrackEntity] = useState(false);
  const [openNewFastTrackEntity, setOpenNewFastTrackEntity] = useState(false);
  const [openContractLog, setOpenContractLog] = useState(false);
  const [fastTrackUpdated, setFastTrackUpdated] = useState(false);
  const [dataRefFastTrackBusinessUnit, setDataRefFastTrackBusinessUnit] = useState(null);
  const [dataRefFastTrackEntity, setDataRefFastTrackEntity] = useState(null);
  const [dataUtility, setDataUtility] = useState(null);

  return (
    <AGTableContext.Provider
      value={{
        tableApi,
        setTableApi,
        tableData,
        setTableData,
        activeRow,
        setActiveRow,
        update,
        setUpdate,
        isActiveTab,
        setIsActiveTab,
        openDelete,
        setOpenDelete,
        openMultiDelete,
        setOpenMultiDelete,
        openNewContract,
        setOpenNewContract,
        openEdit,
        setOpenEdit,
        openArchive,
        setOpenArchive,
        openMultiArchive,
        setOpenMultiArchive,
        openEditUser,
        setOpenEditUser,
        openNewGroup,
        setOpenNewGroup,
        openClause,
        setOpenClause,
        openNewClause,
        setOpenNewClause,
        openDownload,
        setOpenDownload,
        dataUtility,
        setDataUtility,
        openFastTrackBusinessUnit,
        setOpenFastTrackBusinessUnit,
        openNewFastTrackBusinessUnit,
        setOpenNewFastTrackBusinessUnit,
        openFastTrackEntity,
        setOpenFastTrackEntity,
        openNewFastTrackEntity,
        setOpenNewFastTrackEntity,
        openContractLog,
        setOpenContractLog,
        fastTrackUpdated,
        setFastTrackUpdated,
        dataRefFastTrackBusinessUnit,
        setDataRefFastTrackBusinessUnit,
        dataRefFastTrackEntity,
        setDataRefFastTrackEntity,
      }}
    >
      {children}
    </AGTableContext.Provider>
  );
}

AGTableProvider.propTypes = {
  children: PropTypes.any,
};
