import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@mui/material';

import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import { GeoSelector } from '../../components';

function GoverningLaw() {
  const dispatch = useDispatch();
  const { currentSection, currentQuestion, currentQuestionId } = useSelector(
    (state) => state.AnswerQuestions
  );

  const { answer: currentAnswer } = currentQuestion;
  const [allowedLocations, setAllowedLocations] = useState(
    currentAnswer?.allowedLocations || []
  );
  const [excludedLocations, setExcludedLocations] = useState(
    currentAnswer?.excludedLocations || []
  );

  useEffect(() => {
    updateAnswer({
      allowedLocations,
      excludedLocations,
    });
  }, [allowedLocations, excludedLocations]);

  useEffect(() => {
    const { answer: ans } = currentQuestion || {};
    setAllowedLocations(ans?.allowedLocations || []);
    setExcludedLocations(ans?.excludedLocations || []);
  }, [currentQuestionId]);

  function updateAnswer(newAnswer) {
    return dispatch(SetAnswer(currentSection._id, currentQuestion._id, newAnswer));
  }

  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 10 }}>
      <Grid item>
        <GeoSelector
          includeLocations={allowedLocations}
          excludeLocations={excludedLocations}
          onIncludeLocationsChanged={(l) => setAllowedLocations(l)}
          onExcludeLocationsChanged={(l) => setExcludedLocations(l)}
        />
      </Grid>
    </Grid>
  );
}

export default GoverningLaw;
