import {
  SET_MODEL_RULES,
  SET_QUESTION_TYPES,
  SET_QUESTIONNAIRE,
} from '../action-types/questionBuilderTypes';

const initialState = {
  questionTypes: [],
  modelRules: [],
  questionnaire: null,
};

function questionBuilderReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MODEL_RULES:
      const { modelRules } = action.payload;
      return {
        ...state,
        modelRules,
      };
    case SET_QUESTION_TYPES: {
      const { questionTypes } = action.payload;
      return {
        ...state,
        questionTypes,
      };
    }
    case SET_QUESTIONNAIRE: {
      const { questionnaire } = action.payload;
      return {
        ...state,
        questionnaire,
      };
    }
    default:
      return state;
  }
}

export default questionBuilderReducer;
