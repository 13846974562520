import React from 'react';
// import { useIdleTimer } from 'react-idle-timer';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
  Outlet,
} from 'react-router-dom';

import AuthenticatedRoute from './auth/AuthenticatedRoute';
import UnAuthenticatedRoute from './auth/UnAuthenticatedRoute';
import { useAuthUpdateContext } from './auth/AuthUpdateProvider';
import { setErrMsg, setSuccessMsg } from './redux/actions/snackbarActions';
import theme from './styles/MaterialTheme';
import CustomThemeProvider from './utils/CustomThemeProvider';
import { lazyWithRetry } from './utils/ReloadUtils';
import {
  ALL_PERMISSIONS,
  BLACKBOILER_ADMIN_PERMISSION,
  COMPANY_ADMIN_PERMISSION,
  CONTRACTS_PERMISSION,
  UPLOAD_CONTRACT_PERMISSION,
  CLAUSE_EDITOR_PERMISSION,
  PLAYBOOK_BUILDER_PERMISSION,
  METRICS_DASHBOARD_PERMISSION,
  METRICS_FASTTRACK_PERMISSION,
  FAST_TRACK_PERMISSION,
  REPORTING_DASHBOARD_PERMISSION,
  ACCOUNT_ADMIN_PERMISSION,
  POA_INTAKE_PERMISSION,
  POA_REVIEW_PERMISSION,
  POA_GEMS_PERMISSION,
} from './groups.config';
import {
  HOME_ROUTE,
  LOGIN_ROUTE,
  CLAUSES_ROUTE,
  CONTRACTS_ROUTE,
  CONTRACTS_NEW_ROUTE,
  USERS_ROUTE,
  USERS_NEW_ROUTE,
  USERS_EDIT_ROUTE,
  SETTINGS_ROUTE,
  DOCSERVER_ROUTE,
  DOCSERVER_READONLY_ROUTE,
  ANALYTICS_ROUTE,
  MODEL_ROUTE,
  ACCOUNT_ROUTE,
  MODEL_EDIT_ROUTE,
  FAST_TRACK_ROUTE,
  REPORTING_ROUTE,
  TENANTS_ROUTE,
  TENANTS_NEW_ROUTE,
  POA_ROUTE,
  INTEGRATIONS_ROUTE,
} from './routes.config';
import { SnackbarCustom } from './components';
import ViewPoA from './pages/ViewPoA';

// Playbook Builder: Custom Build Point Edit Flow
const PlaybookBuilderCustomBuildPointEdit1 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/point-edit/step1')
);
const PlaybookBuilderCustomBuildPointEdit2 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/point-edit/step2')
);
const PlaybookBuilderCustomBuildPointEdit3 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/point-edit/step3')
);
// const PlaybookBuilderCustomBuildPointEdit4 = lazyWithRetry(() => import('./pages/PlaybookBuilder/custom-build/point-edit/step4'));
// Playbook Builder: Custom Build FXI Flow
const PlaybookBuilderCustomBuildFxi1 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/paragraph-insert/step1')
);
const PlaybookBuilderCustomBuildFxi2 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/paragraph-insert/step2')
);
const PlaybookBuilderCustomBuildFxi3 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/paragraph-insert/step3')
);
const PlaybookBuilderCustomBuildFxi3Tail = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/paragraph-insert/step3-tail')
);
const PlaybookBuilderCustomBuildFxi4 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/paragraph-insert/step4')
);
const PlaybookBuilderCustomBuildFxi5 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/paragraph-insert/step5')
);
const PlaybookBuilderCustomBuildFxi5PresenceThreshold = lazyWithRetry(
  () =>
    import(
      './pages/PlaybookBuilder/custom-build/paragraph-insert/step5-presence-threshold'
    )
);
const PlaybookBuilderCustomBuildFxi5ContextThreshold = lazyWithRetry(
  () =>
    import(
      './pages/PlaybookBuilder/custom-build/paragraph-insert/step5-context-threshold'
    )
);
const PlaybookBuilderCustomBuildFxi5TextRange = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/paragraph-insert/step5-text-range')
);
// Playbook Builder: Leaf List Edit Flow
const PlaybookBuilderCustomBuildLeafList1 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/leaf-list-edit/step1')
);
const PlaybookBuilderCustomBuildLeafList2 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/leaf-list-edit/step2')
);
const PlaybookBuilderCustomBuildLeafList3 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/leaf-list-edit/step3')
);
// Playbook Builder: Governing Law
const PlaybookBuilderCustomBuildGoverningLaw1 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/governing-law/step1')
);
const PlaybookBuilderCustomBuildGoverningLaw2 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/governing-law/step2')
);
const PlaybookBuilderCustomBuildGoverningLaw3 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/governing-law/step3')
);
// Playbook Builder: Governing Forum
const PlaybookBuilderCustomBuildForum1 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/forum/step1')
);
const PlaybookBuilderCustomBuildForum2 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/forum/step2')
);
const PlaybookBuilderCustomBuildForum3 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/forum/step3')
);
// Playbook Builder: Term
const PlaybookBuilderCustomBuildTerm1 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/term/step1')
);
const PlaybookBuilderCustomBuildTerm2 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/term/step2')
);
const PlaybookBuilderCustomBuildTerm3 = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/term/step3')
);
// Playbook Builder: Import Rules
const PlaybookBuilderImportRules = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/ImportRules/ImportRules')
);
// Playbook Builder: Answer Questions
const PlaybookBuilderAnswerQuestionsSectionSelection = lazyWithRetry(
  () =>
    import('./pages/PlaybookBuilder/answer-questions/SectionSelection/SectionSelection')
);
const PlaybookBuilderAnswerQuestionsStatus = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/answer-questions/Status/Status')
);
const PlaybookBuilderAnswerQuestionsStepController = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/answer-questions/StepController/StepController')
);
// Playbook Builder: Question Builder
const PlaybookBuilderQuestionBuilder = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/question-builder/QuestionBuilder/QuestionBuilder')
);
// Playbook Builder: General
const PlaybookBuilderCustomBuild = lazyWithRetry(
  () => import('./pages/PlaybookBuilder/custom-build/CustomBuild/CustomBuild')
);
const PlaybookDashboard = lazyWithRetry(
  () => import('./pages/PlaybookDashboard/PlaybookDashboard')
);
const PlaybookDetails = lazyWithRetry(
  () => import('./pages/PlaybookDetails/PlaybookDetails')
);
const RuleDetails = lazyWithRetry(() => import('./pages/RuleDetails/RuleDetails'));

const Login = lazyWithRetry(() => import('./pages/Login/Login'));
const Home = lazyWithRetry(() => import('./pages/Home/Home'));
const Contracts = lazyWithRetry(() => import('./pages/Contracts/Contracts'));
const NewContracts = lazyWithRetry(() => import('./pages/NewContracts/NewContracts'));
const DocServer = lazyWithRetry(() => import('./pages/DocServer/DocServer'));
const DocServerReadOnly = lazyWithRetry(
  () => import('./pages/DocServerReadOnly/DocServerReadOnly')
);
const ClauseLibrary = lazyWithRetry(() => import('./pages/ClauseLibrary/ClauseLibrary'));
const Analytics = lazyWithRetry(() => import('./pages/Analytics/Analytics'));
const Settings = lazyWithRetry(() => import('./pages/Settings/Settings'));
const Tenants = lazyWithRetry(() => import('./pages/Tenants/Tenants'));
const NewTenant = lazyWithRetry(() => import('./pages/NewTenant/NewTenant'));
const Users = lazyWithRetry(() => import('./pages/Users/Users'));
const UsersUpdate = lazyWithRetry(() => import('./pages/UsersUpdate/UsersUpdate'));
const FastTrack = lazyWithRetry(() => import('./pages/FastTrack/FastTrack'));
const POA = lazyWithRetry(() => import('./pages/POA/POA'));
const Reporting = lazyWithRetry(() => import('./pages/Reporting/Reporting'));
const NotFound = lazyWithRetry(() => import('./pages/NotFound/NotFound'));
const Account = lazyWithRetry(() => import('./pages/Account/Account'));
const Integrations = lazyWithRetry(() => import('./pages/Integrations/Integrations'));
const IntegrationDetails = lazyWithRetry(
  () => import('./pages/IntegrationDetails/IntegrationDetails')
);

const BaseAppBar = lazyWithRetry(() => import('./components/BaseAppBar/BaseAppBar'));

const NewPlaybookDashboard = lazyWithRetry(
  () => import('./pages/PlaybookDashboard/NewPlaybookDashboard/NewPlaybookDashboard')
);
const NewPlaybookDetails = lazyWithRetry(
  () => import('./pages/PlaybookDetails/NewPlaybookDetails/NewPlaybookDetails')
);
const PlaybookDataGrid = lazyWithRetry(
  () => import('./pages/PlaybookDataGrid/PlaybookDataGrid')
);

function Layout() {
  const successMsg = useSelector((state) => state.Snackbar.successMsg, shallowEqual);
  const errMsg = useSelector((state) => state.Snackbar.errMsg, shallowEqual);

  const dispatch = useDispatch();

  const setSuccess = (value) => dispatch(setSuccessMsg(value));
  const setErr = (value) => dispatch(setErrMsg(value));

  return (
    <div>
      <BaseAppBar />
      <Outlet />
      <SnackbarCustom severity="warning" msg={errMsg} setMsg={setErr} />
      <SnackbarCustom severity="success" msg={successMsg} setMsg={setSuccess} />
    </div>
  );
}

function Routing({ returnTo = null }) {
  const { config } = useAuthUpdateContext();
  // const { logout } = useAuthUpdateContext();

  // const idleLogout = () => logout();

  // useIdleTimer({
  //     timeout: ABCCORP_IDLE_TIMEOUT,
  //     onIdle: idleLogout,
  // });

  const { questionnaire } = useSelector((state) => state.AnswerQuestions);
  const sections = questionnaire?.sections || [];

  return (
    <CustomThemeProvider theme={theme} productionPrefix="Routing-JSS-">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to={HOME_ROUTE} />} />
          <Route path="/bbweb" element={<Navigate to={HOME_ROUTE} />} />
          <Route path="/bbweb" element={<Layout />}>
            <Route
              index
              path={LOGIN_ROUTE}
              element={
                <UnAuthenticatedRoute>
                  <Login returnTo={returnTo} />
                </UnAuthenticatedRoute>
              }
            />
            <Route
              path={HOME_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={ALL_PERMISSIONS}>
                  <Home />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={CLAUSES_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[CLAUSE_EDITOR_PERMISSION]}>
                  <ClauseLibrary returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={CONTRACTS_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[CONTRACTS_PERMISSION]}>
                  <Contracts returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={DOCSERVER_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[CONTRACTS_PERMISSION]}>
                  <DocServer returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={DOCSERVER_READONLY_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[CONTRACTS_PERMISSION]}>
                  <DocServerReadOnly returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={CONTRACTS_NEW_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[UPLOAD_CONTRACT_PERMISSION]}>
                  <NewContracts returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route exact path={INTEGRATIONS_ROUTE}>
              {/* /!* Integrations Endpoints *!/ */}
              <Route
                exact
                index
                element={
                  <AuthenticatedRoute routePermissions={[BLACKBOILER_ADMIN_PERMISSION]}>
                    <Integrations returnTo={returnTo} />
                  </AuthenticatedRoute>
                }
              />
              <Route
                exact
                path={`${INTEGRATIONS_ROUTE}/:integrationId`}
                element={
                  <AuthenticatedRoute routePermissions={[BLACKBOILER_ADMIN_PERMISSION]}>
                    <IntegrationDetails returnTo={returnTo} />
                  </AuthenticatedRoute>
                }
              />
            </Route>
            {/* MODEL ROUTES */}
            <Route
              exact
              path={MODEL_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                  {config?.showNewPlaybookBuilder ? (
                    <NewPlaybookDashboard returnTo={returnTo} />
                  ) : (
                    <PlaybookDashboard returnTo={returnTo} />
                  )}
                </AuthenticatedRoute>
              }
            />

            <Route exact path={`${MODEL_EDIT_ROUTE}/:id`}>
              {/* /!* Model Dev Endpoints *!/ */}
              <Route
                exact
                index
                element={
                  <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                    {config?.showNewPlaybookBuilder ? (
                      <NewPlaybookDetails returnTo={returnTo} />
                    ) : (
                      <PlaybookDetails returnTo={returnTo} />
                    )}
                  </AuthenticatedRoute>
                }
              />
              <Route
                exact
                path="data-grid"
                element={
                  <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                    <PlaybookDataGrid returnTo={returnTo} />
                  </AuthenticatedRoute>
                }
              />
              <Route
                exact
                path="rules/import-rules/1"
                element={
                  <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                    <PlaybookBuilderImportRules returnTo={returnTo} />
                  </AuthenticatedRoute>
                }
              />
              <Route
                exact
                path="rule/:ruleId"
                element={
                  <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                    <RuleDetails returnTo={returnTo} />
                  </AuthenticatedRoute>
                }
              />
              <Route
                exact
                path="rules/custom-build"
                element={
                  <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                    <PlaybookBuilderCustomBuild returnTo={returnTo} />
                  </AuthenticatedRoute>
                }
              />
              <Route exact path="rules/pointedit">
                {/* /!* Model Dev Endpoints: POINT EDIT FLOW *!/ */}
                <Route
                  exact
                  path="1"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildPointEdit1 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="2"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildPointEdit2 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="3"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildPointEdit3 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
              </Route>
              <Route exact path="rules/fxi">
                {/* /!* Model Dev Endpoints: FXI FLOW *!/ */}
                <Route
                  exact
                  path="1"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi1 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="2"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi2 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="3"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi3 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="3/tail"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi3Tail returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="4"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi4 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="5"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi5 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="presence-threshold"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi5PresenceThreshold
                        returnTo={returnTo}
                      />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="5/sample"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi5ContextThreshold
                        returnTo={returnTo}
                      />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="5/text-range"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildFxi5TextRange returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
              </Route>
              <Route exact path="rules/leaf-list">
                <Route
                  exact
                  path="1"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildLeafList1 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="2"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildLeafList2 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="3"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildLeafList3 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
              </Route>
              <Route exact path="rules/governing-law">
                <Route
                  exact
                  path="1"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildGoverningLaw1 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="2"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildGoverningLaw2 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="3"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildGoverningLaw3 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
              </Route>
              <Route exact path="rules/forum">
                <Route
                  exact
                  path="1"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildForum1 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="2"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildForum2 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="3"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildForum3 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
              </Route>
              <Route exact path="rules/term">
                <Route
                  exact
                  path="1"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildTerm1 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="2"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildTerm2 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="3"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderCustomBuildTerm3 returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
              </Route>
              <Route exact path="rules/aq">
                {/* /!* Model Dev Endpoints: POINT EDIT FLOW *!/ */}
                <Route
                  exact
                  path="select"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderAnswerQuestionsSectionSelection
                        returnTo={returnTo}
                      />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  exact
                  path="status"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderAnswerQuestionsStatus returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
                {sections.map(({ className }) => (
                  <Route
                    key={className}
                    exact
                    path={className}
                    element={
                      <AuthenticatedRoute
                        routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}
                      >
                        <PlaybookBuilderAnswerQuestionsStepController
                          returnTo={returnTo}
                        />
                      </AuthenticatedRoute>
                    }
                  />
                ))}
              </Route>
              <Route exact path="rules/qb">
                {/* /!* Model Dev Endpoints: QUESTION BUILDER FLOW *!/ */}
                <Route
                  exact
                  path="base"
                  element={
                    <AuthenticatedRoute routePermissions={[PLAYBOOK_BUILDER_PERMISSION]}>
                      <PlaybookBuilderQuestionBuilder returnTo={returnTo} />
                    </AuthenticatedRoute>
                  }
                />
              </Route>
            </Route>

            {/* /!* TODO: ADD FXD routes and associated permissions *!/ */}

            {/* /!* General *!/ */}
            <Route
              exact
              path={USERS_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[COMPANY_ADMIN_PERMISSION]}>
                  <Users returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={USERS_NEW_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[COMPANY_ADMIN_PERMISSION]}>
                  <UsersUpdate returnTo={returnTo} mode="new" />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={USERS_EDIT_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[COMPANY_ADMIN_PERMISSION]}>
                  <UsersUpdate returnTo={returnTo} mode="edit" />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={FAST_TRACK_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[FAST_TRACK_PERMISSION]}>
                  <FastTrack returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={POA_ROUTE}
              element={
                <AuthenticatedRoute
                  routePermissions={[
                    POA_INTAKE_PERMISSION,
                    POA_REVIEW_PERMISSION,
                    POA_GEMS_PERMISSION,
                  ]}
                >
                  <POA returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={`${POA_ROUTE}/:reqId`}
              element={
                <AuthenticatedRoute routePermissions={[POA_REVIEW_PERMISSION]}>
                  <ViewPoA returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={ANALYTICS_ROUTE}
              element={
                <AuthenticatedRoute
                  routePermissions={[
                    METRICS_DASHBOARD_PERMISSION,
                    METRICS_FASTTRACK_PERMISSION,
                  ]}
                >
                  <Analytics returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={SETTINGS_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[BLACKBOILER_ADMIN_PERMISSION]}>
                  <Settings returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={TENANTS_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[BLACKBOILER_ADMIN_PERMISSION]}>
                  <Tenants returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={TENANTS_NEW_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[BLACKBOILER_ADMIN_PERMISSION]}>
                  <NewTenant returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={REPORTING_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[REPORTING_DASHBOARD_PERMISSION]}>
                  <Reporting returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              exact
              path={ACCOUNT_ROUTE}
              element={
                <AuthenticatedRoute routePermissions={[ACCOUNT_ADMIN_PERMISSION]}>
                  <Account returnTo={returnTo} />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="*"
              element={
                <AuthenticatedRoute routePermissions={[ALL_PERMISSIONS]}>
                  <NotFound />
                </AuthenticatedRoute>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={HOME_ROUTE} />} />
        </Routes>
      </Router>
    </CustomThemeProvider>
  );
}

Routing.propTypes = {
  returnTo: PropTypes.any,
};

export default Routing;
