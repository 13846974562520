import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle as MuiDialogTitle,
} from '@mui/material';

import BlackBoilerLogo from '../../assets/images/bb_logo_oneline.png';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { getSubdomain } from '../../utils/OnboardingUtils';
import { Flex, BaseDialogPage } from '../../components';
import OnboardingThankYou from './OnboardingThankYou';
import OnboardingAnswerQuestions from './OnboardingAnswerQuestions';
import OnboardingIntegration from './OnboardingIntegration';

const DialogTitle = ({ children, onClose, ...other }) => {
  return (
    <MuiDialogTitle sx={{ padding: '20px' }} {...other}>
      <Flex.H sx={{ justifyContent: 'space-between' }}>
        <Box sx={{ width: '40px' }} />
        <Typography variant="h6" sx={{ width: '100%' }}>
          {children}
        </Typography>
        {onClose && (
          <Box>
            <IconButton aria-label="close" onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Flex.H>
    </MuiDialogTitle>
  );
};

const OnboardingDialog = ({ open, handleClose, createdModelId }) => {
  const [mainActiveStep, setMainActiveStep] = useState(0);
  const [isChangedPreferredModel, setIsChangedPreferredModel] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const subdomain = getSubdomain();

  const { config } = useAuthUpdateContext();
  const { showOnboarding, showOnboardingIntegration } = config || {};

  const stepperSteps = useMemo(() => {
    const steps = [];

    if (showOnboardingIntegration && !showOnboarding) {
      steps.push('SETUP INTEGRATION');
    } else if (showOnboardingIntegration) {
      steps.push('WELCOME', 'STEP 1: SETUP PLAYBOOK', 'STEP 2: SETUP INTEGRATION');
    } else {
      steps.push('SETUP PLAYBOOK');
    }

    return steps;
  }, [showOnboarding, showOnboardingIntegration]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      fullWidth
      disableEscapeKeyDown
      open={open}
      maxWidth={showThankYou ? 'xs' : 'lg'}
      onClose={(event, reason) => {
        if (reason && reason === 'backdropClick') return;
        handleClose(event);
      }}
    >
      {showThankYou ? (
        <OnboardingThankYou {...{ handleClose, isChangedPreferredModel, subdomain }} />
      ) : (
        <>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Flex.V
              sx={{
                gap: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={BlackBoilerLogo}
                style={{ width: '300px' }}
                alt="BlackBoiler Company Logo"
              />
              <Typography variant="h1">{stepperSteps[mainActiveStep]}</Typography>
            </Flex.V>
          </DialogTitle>

          <DialogContent dividers sx={{ padding: '32px' }}>
            {mainActiveStep === 0 && showOnboarding && showOnboardingIntegration && (
              <BaseDialogPage
                title="Setup Guide"
                extendedTitle="Let's complete the following steps before getting started"
                rButtonText="Start"
                rButtonOnClick={() => setMainActiveStep(1)}
                showLButton={false}
                showTopNav={false}
              >
                <Flex.V sx={{ gap: '12px' }}>
                  <Typography variant="h2">STEP 1: SETUP PLAYBOOK</Typography>
                  <Typography variant="h2">STEP 2: SETUP INTEGRATION</Typography>
                </Flex.V>
              </BaseDialogPage>
            )}

            {(mainActiveStep === 1 || !showOnboardingIntegration) && (
              <OnboardingAnswerQuestions
                {...{
                  createdModelId,
                  showOnboardingIntegration,
                  setMainActiveStep,
                  setIsChangedPreferredModel,
                  setShowThankYou,
                }}
              />
            )}

            {(mainActiveStep === 2 || !showOnboarding) && (
              <OnboardingIntegration {...{ showOnboarding, setShowThankYou }} />
            )}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

OnboardingDialog.propTypes = {
  createdModelId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default OnboardingDialog;
