import React from 'react';
import PropTypes from 'prop-types';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Divider, Button, CircularProgress } from '@mui/material';

import { Flex } from '../../components';
import BaseDialogPageHeader from './BaseDialogPageHeader';

const BaseDialogPage = ({
  children,
  title,
  extendedTitle,
  description,
  lButtonOnClick = (f) => f,
  lButtonText = 'Back',
  rButtonLoading,
  rButtonOnClick = (f) => f,
  rButtonText = 'Next',
  currentStep = null,
  totalSteps = null,
  showTopNav = true,
  showHeader = true,
  showFooter = true,
  showLButton = true,
  showRButton = true,
  rButtonDisabled = false,
  lButtonDisabled = false,
}) => {
  const renderButtons = (
    <Flex.H sx={{ justifyContent: showLButton ? 'space-between' : 'end' }}>
      {showLButton && (
        <Button variant="outlined" onClick={lButtonOnClick} disabled={lButtonDisabled}>
          <KeyboardBackspaceIcon className="mr-2" fontSize="large" />
          {lButtonText}
        </Button>
      )}

      {showRButton && (
        <Button
          color="primary"
          variant="contained"
          onClick={rButtonOnClick}
          disabled={rButtonDisabled}
        >
          {rButtonLoading && (
            <CircularProgress size="20px" sx={{ marginRight: '12px', color: '#fff' }} />
          )}
          {rButtonText}
        </Button>
      )}
    </Flex.H>
  );

  return (
    <Box>
      {showTopNav && <Box sx={{ marginBottom: '20px' }}>{renderButtons}</Box>}

      <Box sx={{ padding: '0 5%' }}>
        {showHeader && (
          <BaseDialogPageHeader
            title={title}
            extendedTitle={extendedTitle}
            description={description}
            currentStep={currentStep}
            totalSteps={totalSteps}
          />
        )}

        {children}
      </Box>

      {showFooter && (
        <>
          <Divider sx={{ margin: '32px -32px' }} />
          {renderButtons}
        </>
      )}
    </Box>
  );
};

BaseDialogPage.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  extendedTitle: PropTypes.string,
  description: PropTypes.string,
  lButtonOnClick: PropTypes.func,
  lButtonText: PropTypes.string,
  rButtonOnClick: PropTypes.func,
  rButtonText: PropTypes.string,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  showTopNav: PropTypes.bool,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  showLButton: PropTypes.bool,
  showRButton: PropTypes.bool,
  rButtonDisabled: PropTypes.bool,
  lButtonDisabled: PropTypes.bool,
};

export default BaseDialogPage;
