import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import { Stepper, Step, StepLabel } from '@mui/material';

import { SETTINGS_ENDPOINT, MODELS_BY_QUESTIONNAIRE } from '../../endpoints.config';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { CreateModelFromInputs } from '../../redux/actions/modelActions';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { GetQuestionnairesByContractType } from '../../requests/questionnaire';
import { capitalizeFirstLetter } from '../../utils/formatting';
import { Flex } from '../../components';
import OnboardingStepContent from './OnboardingStepContent';

const STEPPER_STEPS = [
  'Create Playbook',
  'Select Questions',
  'Answer Question Status',
  'Answer Questions',
];

const OnboardingAnswerQuestions = ({
  createdModelId = null,
  showOnboardingIntegration,
  setMainActiveStep,
  setIsChangedPreferredModel,
  setShowThankYou,
}) => {
  const { getAuthHeader, setConfig, config } = useAuthUpdateContext();

  const {
    setError,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    clearErrors,
    resetField,
  } = useForm({
    defaultValues: {
      name: '',
      contractType: '',
      defaultQuestionnaire: '',
    },
  });

  const [defaultQuestionnaires, setDefaultQuestionnaires] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [modelId, setModelId] = useState(createdModelId);
  const [contractTypes, setContractTypes] = useState([]);
  const [isCreateLoading, setIsCreateLoading] = useState(false);

  const dispatch = useDispatch();

  const setErr = (value) => dispatch(setErrMsg(value));

  const getQuestionnairesByContractType = (type) => {
    getAuthHeader().then((headers) => {
      GetQuestionnairesByContractType(headers, type)
        .then((data) => {
          // If there is one default questionare, set setValue
          if (data.length === 1) {
            setValue('defaultQuestionnaire', data[0]._id);
          }
          const validQuestionnaires = data.filter(
            (q) => q.questions.length && q.playbookIds.length
          );
          setDefaultQuestionnaires(validQuestionnaires);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const fetchSrcPlaybook = async (questionnaireId, headers) => {
    try {
      if (!headers) {
        headers = await getAuthHeader();
      }
      const { data: srcPb } = await axios.get(MODELS_BY_QUESTIONNAIRE, {
        headers,
        params: { questionnaireId },
      });
      return srcPb;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const updateCompanySettings = (data) => {
    getAuthHeader().then((headers) => {
      axios
        .post(
          `${SETTINGS_ENDPOINT}`,
          { companyData: data, updatedFieldNames: [] },
          { headers }
        )
        .then(({ data }) => {
          setConfig(data);
        })
        .catch(() => {
          dispatch(setErrMsg('Failed to save changes!'));
        });
    });
  };

  const handleSetActiveStep = (currStep) => {
    setActiveStep(currStep);
  };

  const handleShowResult = () => {
    updateCompanySettings({ ...config, showOnboarding: false });

    if (showOnboardingIntegration) {
      setMainActiveStep((step) => step + 1);
    } else {
      setShowThankYou(true);
    }
  };

  const handleCreate = (data) => {
    if (!getValues('name').length && activeStep === 0) {
      setError('name', { message: 'Playbook name cannot be blank' });
      return;
    }
    if (!getValues('contractType') && activeStep === 0) {
      setError('contractType', { message: 'Must be selected contract type' });
      return;
    }
    if (!defaultQuestionnaires.length && activeStep === 0) {
      setError('contractType', {
        message:
          'Contract type does not have questionnaire, please select a different contract type',
      });
      return;
    }
    if (!getValues('defaultQuestionnaire') && activeStep === 0) {
      setError('defaultQuestionnaire', {
        message: 'Must have a selected questionnaire',
      });
      return;
    }

    setIsCreateLoading(true);

    getAuthHeader().then(async (headers) => {
      const { name, contractType, defaultQuestionnaire } = data;
      // Fetch the base playbook so that the new playbook can inherit properties
      const srcPlaybook = await fetchSrcPlaybook(defaultQuestionnaire, headers);
      if (!srcPlaybook) {
        console.warn(
          `Cannot fetch model questionnaire ${defaultQuestionnaire}. ` +
            `Model will not inherit properties from base model.`
        );
      }

      const modelData = {
        name,
        isDeleteProtected: false,
        stage: 'published',
        contractType,
        defaultQuestionnaire,
        groups: [],
        generateAddendum: false,
        addendumTemplate: null,
        emailOnUpload: false,
        convertPdfToDocx: false,
        onboarding: true,
        similarityModel: srcPlaybook?.similarityModel,
      };

      Promise.resolve(dispatch(CreateModelFromInputs(headers, modelData)))
        .then(
          ({ modelId: newModelId, isChangedPreferredModel: preferredModelChanged }) => {
            setModelId(newModelId);
            setIsChangedPreferredModel(preferredModelChanged);
            handleSetActiveStep(1);
          }
        )
        .catch((err) => {
          console.log('Error! Failed to create q&a model with error:', err);
          setErr('Error! Failed to create q&a model');
        })
        .finally(() => {
          setIsCreateLoading(false);
        });
    });
  };

  useEffect(() => {
    setModelId(createdModelId);
    setShowThankYou(false);
  }, [createdModelId]);

  useEffect(() => {
    if (watch().contractType) {
      // when every change of contract type
      // 1- clean contract type error
      clearErrors(['contractType']);
      // 2- reset default questionnaire field for reset again
      resetField('defaultQuestionnaire');
      getQuestionnairesByContractType(watch().contractType);
    }
    if (watch().defaultQuestionnaire) {
      clearErrors(['defaultQuestionnaire']);
    }
  }, [watch().contractType]);

  useEffect(() => {
    if (watch().name.length) {
      clearErrors(['name']);
    }
  }, [watch().name]);

  useEffect(() => {
    if (watch().defaultQuestionnaire) {
      clearErrors(['defaultQuestionnaire']);
    }
  }, [watch().defaultQuestionnaire]);

  useEffect(() => {
    // set company name
    const name = config?.displayName || config?.name;
    if (name) setValue('name', capitalizeFirstLetter(name));

    if (modelId && activeStep < 1) {
      setActiveStep(1); // if model move forward but not back
    } else {
      // set playbook name with company name
      if (name) setValue('name', capitalizeFirstLetter(name));

      if (config && config.contractTypes) {
        setContractTypes(config.contractTypes);
        if (config.contractTypes.length === 1) {
          getQuestionnairesByContractType(config.contractTypes[0].name);
          setValue('contractType', config.contractTypes[0].name);
        }
      }
    }
  }, [config]);

  return (
    <Flex.V
      sx={{
        gap: '32px',
        '.MuiSvgIcon-root': { fontSize: '2rem !important' },
        '.MuiStepIcon-text': { fontSize: '1.25rem !important', fontWeight: 700 },
      }}
    >
      <Stepper activeStep={activeStep} alternativeLabel>
        {STEPPER_STEPS.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <OnboardingStepContent
        step={activeStep}
        getValues={getValues}
        setValue={setValue}
        errors={errors}
        defaultQuestionnaires={defaultQuestionnaires}
        modelId={modelId}
        contractTypes={contractTypes}
        handleSetActiveStep={handleSetActiveStep}
        handleShowResult={handleShowResult}
        handleCreate={handleSubmit(handleCreate)}
        isCreateLoading={isCreateLoading}
      />
    </Flex.V>
  );
};

OnboardingAnswerQuestions.propTypes = {
  createdModelId: PropTypes.string,
  showOnboardingIntegration: PropTypes.bool,
  setMainActiveStep: PropTypes.func,
  setIsChangedPreferredModel: PropTypes.func,
  setShowThankYou: PropTypes.func,
};

export default OnboardingAnswerQuestions;
