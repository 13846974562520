import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';

import { Flex } from '../../components';
const PageHeader = ({ children, title, rightButton = null, isBlue = false }) => (
  <Flex.V sx={isBlue ? { background: '#f7fafd' } : {}}>
    <Flex.H
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `20px 20px ${children ? 8 : 20}px 20px`,
        gap: '20px',
      }}
    >
      <Typography variant="h1">{title}</Typography>

      {rightButton}
    </Flex.H>

    <Flex.H sx={{ paddingLeft: '20px' }}>{children}</Flex.H>
  </Flex.V>
);

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  rightButton: PropTypes.element,
  isBlue: PropTypes.bool,
};

export default PageHeader;
