import React from 'react';
import PropTypes from 'prop-types';

import withStyles from '@mui/styles/withStyles';
import { Grid, Tooltip, Typography } from '@mui/material';

import TooltipCustomStyles from './styles';

const TooltipCustom = ({ classes, title, children = null }) => (
  <Tooltip
    title={
      <Grid container direction="column">
        {encodeURI(title)
          .split(encodeURI('\\n'))
          .map((text, idx) => (
            <Grid item key={`text-${idx}-${text}`}>
              <Typography className={classes.tooltip}>{decodeURI(text)}</Typography>
            </Grid>
          ))}
      </Grid>
    }
    arrow
  >
    <div>{children}</div>
  </Tooltip>
);

TooltipCustom.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.any,
};

export default withStyles(TooltipCustomStyles)(TooltipCustom);
