import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import YesNoStyles from '../../styles/YesNoStyles';
import { EditPreview } from '../EditPreview';

function MultiSelect({ classes }) {
  const dispatch = useDispatch();
  const { currentSection, currentQuestion } = useSelector(
    (state) => state.AnswerQuestions
  );
  return (
    <>
      <RadioGroup
        row
        aria-label="multiSelect"
        name="multiSelect"
        value={currentQuestion.answer}
        onChange={(evt) =>
          dispatch(SetAnswer(currentSection._id, currentQuestion._id, evt.target.value))
        }
        className={classes.radioGroup}
      >
        {Object.keys(currentQuestion?.options || {}).map((opt) => (
          <FormControlLabel
            value={opt}
            control={<Radio className={classes.radioButton} />}
            label={opt}
            className={classes.formControlLabel}
          />
        ))}
      </RadioGroup>
      <EditPreview question={currentQuestion} />
    </>
  );
}

MultiSelect.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(YesNoStyles)(MultiSelect);
