import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import {
  SetSectionStatus,
  SetCurrentQuestion,
  SetQuestionTypes,
  SetCurrentSection,
} from '../../redux/actions/answerQuestionsActions';
import { setErrMsg } from '../../redux/actions/snackbarActions';
import { GetQuestionTypes } from '../../requests/question';
import {
  PlaybookBuilderPage,
  BaseDialogPage,
  YesNo,
  YesNoTextEditor,
} from '../../components';
import { SECTION_STATUS } from '../AnswerQuestionsStatus/constants';
import FillInTheBlank from './FillInTheBlank';
import GoverningLaw from './GoverningLaw';
import ListEdit from './ListEdit';
import MultiSelect from './MultiSelect';
import Term from './Term';

function AnswerQuestionStepController({ handleBack, isOnboarding }) {
  const { getAuthHeader } = useAuthUpdateContext();

  const dispatch = useDispatch();
  const setErr = (value) => dispatch(setErrMsg(value));

  const { statusLookup, currentSection, currentQuestion, questionTypes } = useSelector(
    (state) => state.AnswerQuestions
  );
  const [currentIdx, setCurrentIdx] = useState(0);

  const sectionsArray = Object.entries(statusLookup).filter(([, sectionVal]) => {
    const { questions } = sectionVal;
    return questions?.length;
  });
  const isLastSection = currentSection._id === sectionsArray[sectionsArray.length - 1][0];

  useEffect(() => {
    if (questionTypes) {
      // No need to fetch if already loaded
      return;
    }

    getAuthHeader().then((headers) => {
      GetQuestionTypes(headers)
        .then((data) => dispatch(SetQuestionTypes(data)))
        .catch((err) => {
          setErr('Failed to retrieve question types');
          console.error(err);
        });
    });
  }, []);

  function submit() {
    const { questions } = currentSection;
    const allAnswered = questions.every(({ answer, type: qType }) => {
      switch (qType) {
        case questionTypes.YES_NO:
        case questionTypes.YES_NO_TEXT_EDITOR:
        case questionTypes.MULTI_SELECT:
          return answer != undefined && answer != null;

        case questionTypes.LIST_EDIT:
          return answer?.included?.length || answer?.excluded?.length;

        case questionTypes.GOVERNING_LAW:
        case questionTypes.FORUM:
          return answer?.allowedLocations?.length;

        case questionTypes.TERM:
          return answer?.preferred && answer?.units;

        default:
          throw new Error(
            `Unable to detect finished section, ${qType} is an unknown question type`
          );
      }
    });

    if (allAnswered) {
      dispatch(SetSectionStatus(currentSection._id, SECTION_STATUS.COMPLETE));
    }

    if (isLastSection) {
      handleBack();
    } else {
      setCurrentIdx(0);
      const nextIndex =
        sectionsArray.findIndex((section) => section[0] === currentSection._id) + 1;
      const nextSection = sectionsArray[nextIndex][1];
      dispatch(SetCurrentSection(nextSection));
      dispatch(SetCurrentQuestion(nextSection.questions[0]));
      dispatch(SetSectionStatus(nextSection._id, SECTION_STATUS.IN_PROGRESS));
    }
  }

  function backLink() {
    if (currentIdx === 0) {
      handleBack();
    } else {
      const idx = currentIdx - 1;
      const currentQuestion = currentSection.questions[idx];
      setCurrentIdx(idx);
      dispatch(SetCurrentQuestion(currentQuestion));
    }
  }

  function nextLink() {
    if (currentIdx === currentSection.questions.length - 1) {
      submit();
    } else {
      const idx = currentIdx + 1;
      const currentQuestion = currentSection.questions[idx];
      setCurrentIdx(idx);
      dispatch(SetCurrentQuestion(currentQuestion));
    }
  }

  const renderContent = () => {
    switch (currentQuestion.type) {
      case questionTypes.YES_NO:
        return <YesNo />;
      case questionTypes.YES_NO_TEXT_EDITOR:
        return <YesNoTextEditor />;
      case questionTypes.FILL_IN_THE_BLANK:
        return <FillInTheBlank />;
      case questionTypes.GOVERNING_LAW:
      case questionTypes.FORUM:
        return <GoverningLaw />;
      case questionTypes.LIST_EDIT:
        return <ListEdit />;
      case questionTypes.MULTI_SELECT:
        return <MultiSelect />;
      case questionTypes.TERM:
        return <Term />;
      default:
        throw new Error(`Unsupported question type ${currentQuestion?.type}`);
    }
  };

  if (!questionTypes) {
    return null;
  }

  if (isOnboarding) {
    return (
      <BaseDialogPage
        title={`${currentSection.name}: ${currentQuestion.text}`}
        extendedTitle={currentQuestion.extendedTitle}
        description={currentQuestion.description}
        currentStep={currentIdx + 1}
        totalSteps={currentSection.questions.length}
        lButtonOnClick={backLink}
        lButtonText={currentIdx === 0 ? 'Back to Status' : 'Back'}
        rButtonOnClick={nextLink}
        rButtonText={
          currentIdx === currentSection.questions.length - 1
            ? isLastSection
              ? 'Finish'
              : 'Next Section'
            : 'Next'
        }
        rButtonDisabled={false}
        showTopNav={false}
      >
        {renderContent()}
      </BaseDialogPage>
    );
  }

  return (
    <PlaybookBuilderPage
      title={`${currentSection.name}: ${currentQuestion.text}`}
      extendedTitle={currentQuestion.extendedTitle}
      description={currentQuestion.description}
      currentStep={currentIdx + 1}
      totalSteps={currentSection.questions.length}
      lButtonOnClick={backLink}
      lButtonText={currentIdx === 0 ? 'Back to Status' : 'Back'}
      rButtonOnClick={nextLink}
      rButtonText={
        currentIdx === currentSection.questions.length - 1
          ? isLastSection
            ? 'Finish'
            : 'Next Section'
          : 'Next'
      }
      rButtonDisabled={false}
    >
      {renderContent()}
    </PlaybookBuilderPage>
  );
}

AnswerQuestionStepController.propTypes = {
  handleBack: PropTypes.func.isRequired,
  isOnboarding: PropTypes.bool,
};

export default AnswerQuestionStepController;
