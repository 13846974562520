import React from 'react';
import { Box } from '@mui/material';
import { TRAIN_TYPES } from '../../config';
import Colors from '../../styles/Colors';

function Nugget({ trainType }) {
  function getTrainTypeColor() {
    switch (trainType?.toUpperCase()) {
      case TRAIN_TYPES.EMPTY:
        return Colors.trainTypeUnchanged;
      case TRAIN_TYPES.POINT.toUpperCase():
        return Colors.trainTypePoint;
      case TRAIN_TYPES.FSI:
        return Colors.trainTypeFSI;
      case TRAIN_TYPES.FPI:
        return Colors.trainTypeFPI;
      case TRAIN_TYPES.FSD:
        return Colors.trainTypeFSD;
      case TRAIN_TYPES.FPD:
        return Colors.trainTypeFPD;
      case TRAIN_TYPES.NSGL:
        return Colors.trainTypeNSGL;
      case TRAIN_TYPES.NSF:
        return Colors.trainTypeNSF;
      case TRAIN_TYPES.NST:
        return Colors.trainTypeNST;
      default:
        return TRAIN_TYPES.EMPTY;
    }
  }

  return (
    <Box
      sx={{
        bgcolor: getTrainTypeColor(),
        color: '#5C6E81',
        px: 2,
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
      }}
    >
      {trainType?.toUpperCase() || 'NO CHANGES'}
    </Box>
  );
}

export default Nugget;
