import Colors from './Colors';

const PlaybookBuilderStyles = () => ({
  // Edit rules
  blueSubHeader: {
    fontWeight: 700,
    textTransform: 'uppercase',
    color: Colors.blue,
  },
  footer: {
    width: '100%',
    bottom: 0,
    paddingBottom: 15,
    paddingTop: 15,
    borderTop: `1px solid ${Colors.grey4}`,
    backgroundColor: Colors.trueWhite,
  },

  // paragraph insert
  // step 2
  yourText: {
    fontWeight: 700,
    textDecoration: 'underline',
    textDecorationColor: Colors.blue,
    textDecorationThickness: 3,
  },
  contractText: {
    fontWeight: 700,
    textDecoration: 'underline',
    textDecorationColor: Colors.grey3,
    textDecorationThickness: 3,
  },
  // step 5
  sectionBlockWrapper: {
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.1)',
    minWidth: '100%',
    display: 'flex',
    '&:hover, &:focus': {
      '& $sectionBlockLeftWrapper': {
        borderLeft: `1px solid ${Colors.blue}`,
        borderTop: `1px solid ${Colors.blue}`,
        borderBottom: `1px solid ${Colors.blue}`,
      },
      '& $sectionBlockRightWrapper': {
        borderRight: `1px solid ${Colors.blue}`,
        borderTop: `1px solid ${Colors.blue}`,
        borderBottom: `1px solid ${Colors.blue}`,
      },
    },
  },
  sectionBlockDivWrapper: {
    minWidth: '100%',
    minHeight: '100%',
  },
  sectionBlockLeftWrapper: {
    backgroundColor: 'rgba(241,244,246,0.5)',
    borderRadius: '6px 0 0 6px',
    border: `1px solid ${Colors.grey8}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    padding: 30,
  },
  sectionBlockRightWrapper: {
    borderRadius: '0 6px 6px 0',
    border: `1px solid ${Colors.grey8}`,
    borderLeft: 0,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    padding: 30,
  },
  sectionBlockRightHeader: {
    fontSize: '1.8rem',
    color: 'black',
  },
  editExamplesLink: {
    textDecoration: 'underline',
    color: Colors.blue,
  },
  // step 5 - insert-text
  insertTextTableHead: {
    backgroundColor: Colors.grey7,
  },
  insertTextCell: {
    fontSize: '1.3rem',
    fontStyle: 'italic',
    borderRight: `1px solid ${Colors.grey8}`,
  },
  insertTextGreen: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '15px 30px',
    color: Colors.green,
    '& p': {
      color: Colors.green,
      fontSize: '1.3rem',
    },
    '& svg': {
      fontSize: 30,
    },
  },
  insertTextRed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '15px 30px',
    color: Colors.red,
    '& p': {
      color: Colors.red,
      fontSize: '1.3rem',
    },
    '& svg': {
      fontSize: 30,
    },
  },
  // Model List
  modelBlock: {
    borderRadius: 6,
    backgroundColor: Colors.grey1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 27,
    paddingBottom: 37,
    marginBottom: 30,
    marginRight: 5,
    marginLeft: 5,
    '&:hover, &:focus': {
      border: `1px solid ${Colors.blue}`,
    },
  },
  editModelTabButton: {
    borderRadius: 6,
    borderBottomLeftRadius: 'unset',
    borderBottomRightRadius: 'unset',
    backgroundColor: Colors.grey1,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 0,
    marginBottom: 1,
    '&:hover, &:focus': {
      border: `1px solid ${Colors.blue}`,
      marginBottom: -1,
      marginTop: -1,
    },
  },
  // Edit model
  ModelStatusPill: {
    borderRadius: 24,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 5,
    marginRight: 5,
    fontSize: 'medium',
  },
  ModelStatusPillStatusdraft: {
    color: Colors.black,
    backgroundColor: '#e4e7ea',
    fontWeight: 'normal',
    verticalAlign: 'middle',
    display: 'inline-block',
    lineHeight: 'normal',
    height: '100%',
  },
  ModelStatusPillStatuspublished: {
    color: Colors.black,
    backgroundColor: '#daf1ef',
    fontWeight: 'normal',
  },
  ModelStatusPillStatusbackup: {
    color: Colors.black,
    backgroundColor: '#b4b0b0',
    fontWeight: 'normal',
    verticalAlign: 'middle',
    display: 'inline-block',
    lineHeight: 'normal',
    height: '100%',
  },
  ModelStatusRuleCount: {
    color: '#748494',
    fontWeight: 'normal',
    fontSize: 'small',
    verticalAlign: 'middle',
    display: 'inline-block',
    lineHeight: 'normal',
    height: '100%',
  },

  // Point Edit

  // step 1
  listButton: {
    fontSize: 13,
    width: '100%',
    borderTop: `1px solid ${Colors.grey8}`,
    borderBottom: `1px solid ${Colors.grey8}`,
    marginTop: -1, // keeps top/bottom borders overlapping.
    paddingTop: 15,
    paddingBottom: 15,
    '&:hover': {
      color: Colors.blue,
      fontWeight: 700,
      '& svg': {
        color: Colors.blue,
      },
    },
  },
  // step 2
  clearFilterButton: {
    width: '100%',
    backgroundColor: 'lightblue',
  },

  // step 4
  // text-range
  greenDot: {
    height: 12,
    width: 12,
    borderRadius: 12,
    backgroundColor: Colors.blue4,
    display: 'inline-block',
    marginRight: 5,
    marginLeft: 15,
  },
  blueDot: {
    height: 12,
    width: 12,
    borderRadius: 12,
    backgroundColor: Colors.blue,
    display: 'inline-block',
    marginRight: 5,
  },
  // end text-range
  PointEditStep4TableHeader: {
    border: `1px solid ${Colors.grey8}`,
    borderRadius: 3,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  PointEditStep4TableHeaderTitle: {
    fontSize: '1.25rem',
    fontWeight: 700,
  },
  EditTypePill: {
    height: 24,
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  EditTypePillStatusrevise: {
    color: '#191919',
    minHeight: 30,
    backgroundColor: Colors.editTypeRevise,
  },
  EditTypePillStatusaccept: {
    color: '#191919',
    minHeight: 30,
    backgroundColor: Colors.editTypeAccept,
  },
  EditTypePillStatusreject: {
    color: '#191919',
    minHeight: 30,
    backgroundColor: Colors.editTypeReject,
  },
  EditTypePillText: {
    marginLeft: 10,
    marginRight: 10,
  },
  trainingDataBlock: {
    backgroundColor: '#f3f8fd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    width: '100%',
    borderRadius: 5,
  },
  buttonBlock: {
    border: `1px solid rgba(${Colors.grey3RGB}, .3)`,
    borderRadius: 5,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: 236,
    width: '100%',
    '& .startBtn': {
      display: 'none',
    },
    '&:hover, &:focus': {
      border: `1px solid ${Colors.grey3}`,
      boxShadow: '0 0 18px 0 rgba(0,0,0,0.1)',
      '& svg': {
        fill: Colors.blue,
      },
      '& .startBtn': {
        display: 'block',
      },
    },
  },
  buttonBlockSelected: {
    border: `1px solid ${Colors.blue}`,
    boxShadow: '0 0 18px 0 rgba(0,0,0,0.1)',
    '& svg': {
      fill: Colors.blue,
    },
    '& .startBtn': {
      display: 'block',
    },
    '&:hover, &:focus': {
      border: `1px solid ${Colors.blue}`,
    },
  },
  customBuildButtonBlock: {
    border: `1px solid rgba(${Colors.grey3RGB}, .3)`,
    borderRadius: 5,
    overflow: 'scroll',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    height: 236,
    width: '100%',
    '& .startBtn': {
      display: 'none',
    },
    '&:hover, &:focus': {
      border: `1px solid ${Colors.grey3}`,
      boxShadow: '0 0 18px 0 rgba(0,0,0,0.1)',
      '& svg': {
        fill: Colors.blue,
      },
      '& .startBtn': {
        display: 'block',
      },
    },
  },
  startBtnWrapper: {
    height: 44,
  },
  startBuildingIconWrapper: {
    height: 44,
    width: 44,
    borderRadius: 44,
    backgroundColor: Colors.grey1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: 20,
      width: 20,
    },
  },
  underlined: {
    textDecoration: 'underline',
  },
  italicized: {
    fontStyle: 'italic',
  },
  strikethroughText: {
    textDecoration: 'line-through',
  },

  // Rules UI
  sectionButtons: {
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 'small',
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'left',
    display: 'inline',
    width: '100%',
    '&:hover': {
      color: Colors.blue,
      fontWeight: 700,
      '& svg': {
        color: Colors.blue,
      },
    },
  },

  // Import Rules
  modelSelect: {
    width: 300,
  },

  // List Edits
  leafListHeader: {
    flexWrap: 'nowrap',
    marginTop: 25,
  },
  leafListIncludeExcludeContainer: {
    backgroundColor: Colors.pageContentBlue,
    padding: 10,
  },
  leafListIncludeExcludeHeader: {
    marginTop: 25,
    marginLeft: 20,
  },
  alignTextCenter: {
    textAlign: 'center',
  },
  width100: {
    width: '100%',
  },

  // General
  modelStatusWrapper: {
    height: 24,
    borderRadius: 12,
    backgroundColor: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 20,
    paddingLeft: 20,
    '&.draft': {
      backgroundColor: `rgba(${Colors.grey2RGB}, .2)`,
    },
    '&.pending': {
      backgroundColor: `rgba(${Colors.yellowRGB}, .3)`,
    },
    '&.published': {
      backgroundColor: `rgba(${Colors.greenRGB}, .3)`,
    },
    '& span': {
      fontFamily: 'Inter, san-serif',
      fontSize: 12, // using px as it lives in a fixed height space
      textTransform: 'uppercase',
      color: 'black',
    },
  },
  playbookBuilderEdit: {
    backgroundColor: 'white',
  },
  modelImg: {
    width: 43,
  },
  rotated: {
    transform: 'rotate(180deg)',
  },
  alert: {
    height: '90%',
    color: Colors.brightRed,
  },
  pillText: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  textDiff: {
    borderRadius: '3px',
    borderStyle: 'solid',
    borderColor: '#222222',
    borderWidth: '0.5pt',
    outline: '0',
    width: '100%',
    overflow: 'scroll',
    fontWeight: 400,
    padding: '18.5px 14px',
    height: '8.5em',
    maxHeight: '8.5em',
  },
  pointEditStep4TextDiffNoBorder: {
    outline: '0',
    width: '100%',
    overflow: 'scroll',
    fontWeight: 400,
    height: '100%',
    maxHeight: '8.5em',
  },
  pointEditStep4TextDiffWrapper: {
    textAlign: 'left',
  },
  bulkStrikethroughButton: {
    width: 175,
    textTransform: 'none',
  },
  bulkEditButton: {
    width: 150,
    textTransform: 'none',
  },
  RulesGridWrapper: {
    width: '100%',
  },
  fullWidth: {
    minWidth: '100%',
  },
  noMargin: {
    margin: 0,
  },
  topMargin: {
    marginTop: 10,
  },
  fontSize14: {
    fontSize: '14px',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  centerText: {
    display: 'flex',
    alignItems: 'center',
  },
  // search input
  clearFilterBtnWrapper: {
    width: '100%',
    height: '100%',
    textAlign: 'right',
  },
  fontSizeSmall: {
    fontSize: 'small',
  },
  // train type pill
  TrainTypePill: {
    borderRadius: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TrainTypePillText: {
    marginLeft: 10,
    marginRight: 10,
    fontWeight: 'bold',
    fontSize: 'x-small',
    textAlign: 'center',
  },
  TrainTypePillTextSizeSmall: {
    fontSize: 'small',
  },
  TrainTypePillStatus: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeUnchanged,
  },
  TrainTypePillStatuspoint: {
    minHeight: 30,
    backgroundColor: Colors.trainTypePoint,
  },
  TrainTypePillStatusGeneric: {
    minHeight: 30,
    borderWidth: 'thin',
    backgroundColor: Colors.trueWhite,
    borderColor: Colors.trainTypeGeneric,
    borderStyle: 'solid',
  },
  TrainTypePillStatusGenericSelected: {
    backgroundColor: Colors.trainTypeGenericSelected,
  },
  TrainTypePillStatusFSI: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeFSI,
  },
  TrainTypePillStatusFPI: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeFPI,
  },
  TrainTypePillStatusFSD: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeFSD,
  },
  TrainTypePillStatusFPD: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeFPD,
  },
  TrainTypePillStatuslist_edit: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeLeafList,
  },
  TrainTypePillStatusNSGL: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeNSGL,
  },
  TrainTypePillStatusNSF: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeNSF,
  },
  TrainTypePillStatusNST: {
    minHeight: 30,
    backgroundColor: Colors.trainTypeNST,
  },
  textHighlighting: {
    fontSize: 14,
  },
  textInsertion: {
    color: Colors.green,
  },
  buttonLightBlue: {
    backgroundColor: Colors.lightBlue3,
  },
  margin25: {
    margin: 25,
  },
});

export default PlaybookBuilderStyles;
