import React from 'react';
import PropTypes from 'prop-types';
import 'draft-js/dist/Draft.css';

import withStyles from '@mui/styles/withStyles';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';
import { Typography } from '@mui/material';

import SignalStrengthStyles from './styles';

export const STRENGTH_LOOKUPS = {
  0: 'Very Weak',
  1: 'Weak',
  2: 'Okay',
  3: 'Strong',
  4: 'Very Strong',
};

const RulesGrid = ({ classes, strength = (f) => f, hideText = false }) => {
  /*
     Strength is measured on a 0 - 4 scale, compliant with the range provided by the MUI library
     */
  switch (strength) {
    case 0:
      return (
        <>
          <SignalCellular0BarIcon
            className={classes[`SignalCellularBarIcon${strength}`]}
          />
          {hideText ? null : (
            <Typography variant="body1" className="d-inline ml-2">
              {STRENGTH_LOOKUPS[strength]}
            </Typography>
          )}
        </>
      );
    case 1:
      return (
        <>
          <SignalCellular1BarIcon
            className={classes[`SignalCellularBarIcon${strength}`]}
          />
          {hideText ? null : (
            <Typography variant="body1" className="d-inline ml-2">
              {STRENGTH_LOOKUPS[strength]}
            </Typography>
          )}
        </>
      );
    case 2:
      return (
        <>
          <SignalCellular2BarIcon
            className={classes[`SignalCellularBarIcon${strength}`]}
          />
          {hideText ? null : (
            <Typography variant="body1" className="d-inline ml-2">
              {STRENGTH_LOOKUPS[strength]}
            </Typography>
          )}
        </>
      );
    case 3:
      return (
        <>
          <SignalCellular3BarIcon
            className={classes[`SignalCellularBarIcon${strength}`]}
          />
          {hideText ? null : (
            <Typography variant="body1" className="d-inline ml-2">
              {STRENGTH_LOOKUPS[strength]}
            </Typography>
          )}
        </>
      );
    case 4:
      return (
        <>
          <SignalCellular4BarIcon
            className={classes[`SignalCellularBarIcon${strength}`]}
          />
          {hideText ? null : (
            <Typography variant="body1" className="d-inline ml-2">
              {STRENGTH_LOOKUPS[strength]}
            </Typography>
          )}
        </>
      );
    default:
      return (
        <>
          <SignalCellular0BarIcon className={classes.SignalCellularBarIcon0} />
          {hideText ? null : (
            <Typography variant="body1" className="d-inline ml-2">
              {STRENGTH_LOOKUPS[0]}
            </Typography>
          )}
        </>
      );
  }
};

RulesGrid.propTypes = {
  classes: PropTypes.object,
  strength: PropTypes.number,
  hideText: PropTypes.bool,
};

export default withStyles(SignalStrengthStyles)(RulesGrid);
