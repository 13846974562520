import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import { LicenseManager, ModuleRegistry, AllEnterpriseModule } from 'ag-grid-enterprise';

import { Box } from '@mui/material';

import { AG_ENTERPRISE_KEY, COLUMN_TYPES } from './AGTable.utils';

LicenseManager.setLicenseKey(AG_ENTERPRISE_KEY);
ModuleRegistry.registerModules([AllEnterpriseModule]);

const AGTable = ({
  sx,
  rowData,
  colDefs,
  rowHeight = 42,
  rowBuffer = 60,
  isSelectable = false,
  isMoveable = true,
  isFilterFloating = false,
  isSidebarVisible = false,
  isAutoResize = false,
  pagination = false,
  defaultPagination = 50,
  onRowClick,
  onUpdate,
  onSelect,
}) => {
  // AG Grid needs the columns to be stored in state
  const [AGcolDefs, setAGcolDefs] = useState(colDefs);
  useEffect(() => {
    // Update stored column definitions
    if (colDefs) {
      setAGcolDefs(colDefs);
    }
  }, [colDefs]);

  // Default column settings
  const defaultColDef = useMemo(() => {
    return {
      type: 'string',
      minWidth: 72,
      suppressMovable: !isMoveable,
      floatingFilter: isFilterFloating,
      filterParams: {
        buttons: ['clear'],
      },
    };
  }, []);

  // Row selection settings
  const rowSelection = useMemo(() => {
    return isSelectable
      ? {
          mode: 'multiRow',
          selectAll: 'currentPage',
          enableClickSelection: false,
        }
      : { checkboxes: false, headerCheckbox: false, enableClickSelection: false };
  }, [isSelectable]);

  return (
    <Box
      className="ag-theme-quartz"
      sx={{
        height: '100%',
        '.ag-header-cell-text': { fontWeight: '700' },
        '.ag-root-wrapper': { borderRadius: '4px' },
        ...sx,
      }}
    >
      <AgGridReact
        columnTypes={COLUMN_TYPES}
        columnDefs={AGcolDefs}
        pagination={Boolean(pagination?.length)}
        paginationPageSize={defaultPagination}
        paginationPageSizeSelector={pagination}
        sideBar={isSidebarVisible ? 'columns' : ''}
        autoSizeStrategy={isAutoResize ? { type: 'fitCellContents' } : undefined}
        onRowClicked={(e) => onRowClick?.(e.rowIndex)}
        onModelUpdated={(e) => onUpdate?.(e.api)}
        onSelectionChanged={(e) => onSelect?.(e.api.getSelectedRows())}
        {...{ defaultColDef, rowHeight, rowBuffer, rowData, rowSelection }}
      />
    </Box>
  );
};

AGTable.propTypes = {
  sx: PropTypes.object,
  rowData: PropTypes.any,
  colDefs: PropTypes.any,
  rowHeight: PropTypes.number,
  rowBuffer: PropTypes.number,
  isSelectable: PropTypes.bool,
  isSidebarVisible: PropTypes.bool,
  isAutoResize: PropTypes.bool,
  pagination: PropTypes.arrayOf(PropTypes.number),
  defaultPagination: PropTypes.number,
  onRowClick: PropTypes.func,
  onUpdate: PropTypes.func,
  onSelect: PropTypes.func,
};

export default AGTable;
