import React from 'react';
import { Box } from '@mui/material';
import { TRAIN_TYPES, EDIT_TYPES } from '../../config';
import Colors from '../../styles/Colors';

function StatsCount({ field, fieldValue, name, count }) {
  function getColor() {
    if (field === 'train_type') {
      switch (fieldValue) {
        case TRAIN_TYPES.EMPTY:
          return Colors.trainTypeUnchanged;
        case TRAIN_TYPES.POINT:
          return Colors.trainTypePoint;
        case TRAIN_TYPES.FSI:
          return Colors.trainTypeFSI;
        case TRAIN_TYPES.FPI:
          return Colors.trainTypeFPI;
        case TRAIN_TYPES.FSD:
          return Colors.trainTypeFSD;
        case TRAIN_TYPES.FPD:
          return Colors.trainTypeFPD;
        case TRAIN_TYPES.NSGL:
          return Colors.trainTypeNSGL;
        case TRAIN_TYPES.NSF:
          return Colors.trainTypeNSF;
        case TRAIN_TYPES.NST:
          return Colors.trainTypeNST;
        default:
          return TRAIN_TYPES.EMPTY;
      }
    } else if (field === 'edit_type') {
      switch (fieldValue) {
        case EDIT_TYPES.ACCEPT:
          return Colors.editTypeAccept;
        case EDIT_TYPES.REVISE:
          return Colors.editTypeRevise;
        case EDIT_TYPES.REJECT:
          return Colors.editTypeReject;
        default:
          return '#DFE8FE';
      }
    }
  }

  return (
    <Box
      sx={{
        display: 'inline-block',
        bgcolor: getColor(),
        color: '#5C6E81',
        px: 1,
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
      }}
    >
      {count} {fieldValue || 'no changes'}
    </Box>
  );
}

export default StatsCount;
