import { TSV_EXPORT_SEPARATOR } from '../config';
import { isBool, truthy } from './parsingUtils';

export const downloadFile = ({ data, fileName, fileType }) => {
  // Create a blob with the data we want to download as a file
  const blob = new Blob([data], { type: fileType });
  // Create an anchor element and dispatch a click event on it
  // to trigger a download
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const downloadBlob = (blob, fileName) => {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  link.style.position = 'absolute';
  link.style.visibility = 'hidden';

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const downloadJsonAsFile = (
  data,
  prettyKeyMap = {},
  boolMap = { true: 'true', false: 'false' },
  separator = TSV_EXPORT_SEPARATOR,
  filename = 'data.tsv'
) => {
  const rows = Object.entries(data)
    .map(([key, value]) => {
      const prettyKey = prettyKeyMap?.[key] || key;
      if (isBool(value)) {
        const boolVal = truthy(value);
        return [prettyKey, boolMap?.[boolVal]].join(separator);
      }
      if (typeof value === 'string') {
        return [prettyKey, value.replaceAll(/\t/g, ' ').replaceAll(/\n/g, ' ')].join(
          separator
        );
      }
      if (typeof value === 'number') {
        return [prettyKey, value].join(separator);
      }
      if (!value) {
        return [prettyKey, ''];
      }
      return [prettyKey, JSON.stringify(value)];
    })
    .join('\n');
  const blob = new Blob([rows], { type: 'text/csv;charset=utf-8;' });
  downloadBlob(blob, filename);
};
