import { lazy } from 'react';

import { useAuthUpdateContext } from '../auth/AuthUpdateProvider';

const isReloadKey = 'bb-force-refreshed';

export const pageHasAlreadyBeenForceRefreshed = () =>
  JSON.parse(window.sessionStorage.getItem(isReloadKey) || 'false');

export const setForceReload = (isReload) => {
  const isReloadValue = typeof isReload === 'boolean' ? isReload.toString() : isReload;

  window.sessionStorage.setItem(isReloadKey, isReloadValue);
  const { sendEventMetric } = useAuthUpdateContext();

  if (isReload) {
    sendEventMetric(
      'FORCED_RELOAD',
      'lazyWithRetry made a forced reload after a chunk was not retrieved.'
    );

    // window.location.reload();
  }
};

// Adapted from https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
export const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    try {
      const component = await componentImport();

      return component;
    } catch (error) {
      throw error;
    }
  });
