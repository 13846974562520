import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { SetAnswer } from '../../redux/actions/answerQuestionsActions';
import { NumericalNanoslotSelector } from '..';

function Term() {
  const dispatch = useDispatch();
  const { currentSection, currentQuestion, currentQuestionId } = useSelector(
    (state) => state.AnswerQuestions
  );

  const { answer: currentAnswer } = currentQuestion;
  const [numericalVals, setNumericalVals] = useState(
    currentAnswer || { min: 0, max: 120 }
  );

  useEffect(() => {
    updateAnswer(numericalVals);
  }, [numericalVals]);

  useEffect(() => {
    const { answer: ans } = currentQuestion || {};
    setNumericalVals(ans || {});
  }, [currentQuestionId]);

  function updateAnswer(newAnswer) {
    return dispatch(SetAnswer(currentSection._id, currentQuestion._id, newAnswer));
  }

  const { min, max, preferred } = numericalVals;
  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 10 }}>
      <Grid item>
        <NumericalNanoslotSelector
          initialPreferredValue={preferred}
          initialRange={[min, max]}
          onChange={(newVals) => setNumericalVals(newVals)}
        />
      </Grid>
    </Grid>
  );
}

export default Term;
