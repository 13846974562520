import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { Button, Grid } from '@mui/material';

import {
  CONTRACT_DELETE_ENDPOINT,
  CLAUSES_DELETE_ENDPOINT,
} from '../../endpoints.config';
import { useAuthUpdateContext } from '../../auth/AuthUpdateProvider';
import { useAGTableContext } from '../../contexts/AGTableProvider';
import { BaseDialog } from '../BaseDialog';

const DeleteClauseOrContractDialog = ({ page, onSuccess }) => {
  const { tableApi, activeRow, openDelete, setOpenDelete, update, setUpdate } =
    useAGTableContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [targetRows, setTargetRows] = useState(null);
  const [targetName, setTargetName] = useState('');

  const { getAuthHeader } = useAuthUpdateContext();

  const handleClose = () => {
    setCancel(true);
    setOpenDelete(false);
  };

  const deleteFiles = (deleteList) => {
    getAuthHeader().then((headers) => {
      const promiseList = deleteList.reduce((result, { _id: id }) => {
        const endpoint =
          page === 'contract' ? CONTRACT_DELETE_ENDPOINT : CLAUSES_DELETE_ENDPOINT;
        const promise = axios.delete(`${endpoint}${id}`, { headers }).catch(() => {
          setError(true);
        });
        return [promise, ...result];
      }, []);

      axios
        .all(promiseList)
        .then(() => {
          setOpenDelete(false);
          setUpdate(!update);
          setLoading(false);
          onSuccess?.();
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    });
  };

  const handleDelete = () => {
    setLoading(true);

    if (page === 'contract') {
      deleteFiles([targetRows]);
    } else {
      deleteFiles(Object.values(targetRows));
    }
  };

  useEffect(() => {
    if (tableApi) {
      const toDelete = tableApi.getDisplayedRowAtIndex(activeRow)?.data;

      if (page === 'contract') {
        const { name } = toDelete || {};
        setTargetName(name);
      } else {
        const {
          as_receiving_party: asReceivingParty,
          as_receiving_party_you: asReceivingPartyYou,
        } = toDelete;
        // Check if clause exists because some databases have partial data
        if (asReceivingParty) {
          const { title } = asReceivingParty;
          setTargetName(title);
        } else if (asReceivingPartyYou) {
          const { title } = asReceivingPartyYou;
          setTargetName(title);
        }
      }

      setTargetRows(toDelete);
    }
  }, [openDelete]);

  return (
    <div>
      <BaseDialog
        showContractIcon
        open={openDelete}
        purpose="delete"
        title={`Delete ${page === 'contract' ? 'Contract' : 'Clause'}`}
        subtitle={`Are you sure you wish to delete the ${page === 'contract' ? 'contract' : 'clause'} ${targetName}?`}
        {...{ loading, error, cancel, handleClose }}
      >
        <Grid item>
          <Button onClick={handleClose} variant="outlined" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleDelete}
            color="error"
            variant="contained"
            fullWidth
            data-cy="deleteContract"
          >
            Delete
          </Button>
        </Grid>
      </BaseDialog>
    </div>
  );
};

DeleteClauseOrContractDialog.propTypes = {
  page: PropTypes.string.isRequired,
  onSuccess: PropTypes.func,
};

export default DeleteClauseOrContractDialog;
