import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, InputLabel, TextField, Slider } from '@mui/material';

import { BBSelect, Flex } from '../../components';

const unitOptions = ['Year', 'Month', 'Day'];

const NumericalNanoslotSelector = ({
  min = 0,
  max = 120,
  defaultRange = [0, 120],
  minLabel = 'Min',
  maxLabel = 'Max',
  preferredLabel = 'Preferred',
  initialPreferredValue,
  initialRange,
  onChange,
}) => {
  const [range, setRange] = useState(initialRange || defaultRange);
  const [preferredValue, setPreferredValue] = useState(
    initialPreferredValue || Math.floor((defaultRange[1] - defaultRange[0]) / 2)
  );
  const [editedInput, setEditedInput] = useState('');
  const [editedValue, setEditedValue] = useState('');

  const [units, setUnits] = useState(unitOptions[0]);

  const handleRangeChange = (_event, newValue) => {
    setRange(newValue);

    // Check if preferred value should be updated as a result
    if (preferredValue < newValue[0]) {
      setPreferredValue(newValue[0]);
      onChange?.({ min: newValue[0], max: newValue[1], preferred: newValue[0], units });
    } else if (preferredValue > newValue[1]) {
      setPreferredValue(newValue[1]);
      onChange?.({ min: newValue[0], max: newValue[1], preferred: newValue[1], units });
    } else {
      onChange?.({
        min: newValue[0],
        max: newValue[1],
        preferred: preferredValue,
        units,
      });
    }
  };

  const handlePreferredChange = (_event, newValue) => {
    if (newValue >= range[0] && newValue <= range[1]) {
      setPreferredValue(newValue);
      onChange?.({ min: range[0], max: range[1], preferred: newValue, units });
    } else if (newValue < range[0]) {
      // Block when min is out of range
      setPreferredValue(range[0]);
      onChange?.({ min: range[0], max: range[1], preferred: range[0], units });
    } else if (newValue > range[1]) {
      // Block when max is out of range
      setPreferredValue(range[1]);
      onChange?.({ min: range[0], max: range[1], preferred: range[1], units });
    }
  };

  const handleBlur = (type) => {
    const newVal = Number(editedValue);
    // Reset edited input state
    setEditedInput('');
    setEditedValue('');

    if (type === 'min') {
      // Handle min blur and update preferred when needed
      if (newVal >= min && newVal < range[1]) {
        setRange([newVal, range[1]]);
        if (preferredValue < newVal) {
          setPreferredValue(newVal);
          onChange?.({ min: newVal, max: range[1], preferred: newVal, units });
        } else {
          onChange?.({ min: newVal, max: range[1], preferred: preferredValue, units });
        }
      } else if (newVal < min) {
        setRange([min, range[1]]);
        onChange?.({ min: min, max: range[1], preferred: preferredValue, units });
      }
    } else if (type === 'max') {
      // Handle max blur and update preferred when needed
      if (newVal <= max && newVal > range[0]) {
        setRange([range[0], newVal]);
        if (preferredValue > newVal) {
          setPreferredValue(newVal);
          onChange?.({ min: range[0], max: newVal, preferred: newVal, units });
        } else {
          onChange?.({ min: range[0], max: newVal, preferred: preferredValue, units });
        }
      } else if (newVal > max) {
        setRange([range[0], max]);
        onChange?.({ min: range[0], max: max, preferred: preferredValue, units });
      }
    } else {
      // Handle preferred blur
      if (newVal >= range[0] && newVal <= range[1]) {
        setPreferredValue(newVal);
        onChange?.({ min: range[0], max: range[1], preferred: newVal, units });
      } else if (newVal > range[1]) {
        setPreferredValue(range[1]);
        onChange?.({ min: range[0], max: range[1], preferred: range[1], units });
      } else if (newVal < range[0]) {
        setPreferredValue(range[0]);
        onChange?.({ min: range[0], max: range[1], preferred: range[0], units });
      }
    }
  };

  const handleInputKeyDown = (event) => {
    // Blur on enter key press
    if (event.keyCode === 13) {
      event.target.blur();
    }
  };

  const handleUnitsChange = (evt) => {
    setUnits(evt.target.value);
    onChange?.({
      min: range[0],
      max: range[1],
      preferred: preferredValue,
      units: evt.target.value,
    });
  };

  return (
    <Flex.V
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        '.MuiInputBase-input': { textAlign: 'center' },
      }}
    >
      <BBSelect
        label="Time Units"
        value={units}
        options={unitOptions}
        onChange={handleUnitsChange}
        sx={{ width: 'auto' }}
      />

      <Flex.H
        sx={{
          width: '100%',
          gap: '20px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: '200px', textAlign: 'center' }}>
          <InputLabel id="min-label">{`${minLabel} ${units}`}</InputLabel>
          <TextField
            variant="outlined"
            type="number"
            value={editedInput === 'min' ? editedValue : range[0]}
            inputProps={{
              'aria-labelledby': 'min-label',
            }}
            onChange={(event) => setEditedValue(event.target.value)}
            onClick={() => setEditedInput('min')}
            onBlur={() => handleBlur('min')}
            onKeyDown={handleInputKeyDown}
          />
        </Box>

        <Box sx={{ width: '100%', marginTop: '24px' }}>
          <Box
            sx={{
              width: '100%',
              '.MuiSlider-root': {
                marginTop: '0 !important',
              },
              '.MuiSlider-track': {
                color: '#90caf9',
              },
              '.MuiSlider-thumb': {
                marginTop: '-2px',
              },
            }}
          >
            <Slider
              getAriaLabel={() => 'Numerical nanoslot range'}
              value={range}
              color="secondary"
              onChange={handleRangeChange}
              {...{ min, max }}
            />
          </Box>

          <Box
            sx={{
              width: '100%',
              '.MuiSlider-root': {
                marginTop: '0 !important',
              },
              '.MuiSlider-thumb': {
                marginTop: '-2px',
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '22px solid #0D6DFF',
                color: 'transparent',
              },
            }}
          >
            <Slider
              getAriaLabel={() => 'Numerical nanoslot preferred value'}
              track={false}
              value={preferredValue}
              onChange={handlePreferredChange}
              {...{ min, max }}
            />
          </Box>
        </Box>

        <Box sx={{ width: '200px', textAlign: 'center' }}>
          <InputLabel id="max-label">{`${maxLabel} ${units}`}</InputLabel>
          <TextField
            variant="outlined"
            type="number"
            inputProps={{ 'aria-labelledby': 'max-label' }}
            value={editedInput === 'max' ? editedValue : range[1]}
            onChange={(event) => setEditedValue(event.target.value)}
            onClick={() => setEditedInput('max')}
            onBlur={() => handleBlur('max')}
            onKeyDown={handleInputKeyDown}
          />
        </Box>
      </Flex.H>

      <Box sx={{ width: '200px', textAlign: 'center' }}>
        <InputLabel id="preferred-label">{`${preferredLabel} ${units}`}</InputLabel>
        <TextField
          variant="outlined"
          type="number"
          inputProps={{ 'aria-labelledby': 'preferred-label' }}
          value={editedInput === 'preferred' ? editedValue : preferredValue}
          onChange={(event) => setEditedValue(event.target.value)}
          onClick={() => setEditedInput('preferred')}
          onBlur={() => handleBlur('preferred')}
          onKeyDown={handleInputKeyDown}
        />
      </Box>
    </Flex.V>
  );
};

NumericalNanoslotSelector.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  defaultValue: PropTypes.string,
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  preferredLabel: PropTypes.string,
  initialPreferredValue: PropTypes.number,
  initialRange: PropTypes.array,
  onChange: PropTypes.func,
};

export default NumericalNanoslotSelector;
