import axios from 'axios';

import {
  EVENTLOGS_ENDPOINT,
  EVENTLOGS_LOG_NAMES_ENDPOINT,
  EVENTLOGS_REPORT_NAMES_ENDPOINT,
  ERROR_REPORTING_ENDPOINT,
  LOG_REPORTING_ENDPOINT,
} from '../endpoints.config';

export async function getEventlogs(headers, params) {
  const { data } = await axios.get(EVENTLOGS_ENDPOINT, { headers, params });
  return data;
}

export async function getEventlogsLogNames(headers) {
  const { data } = await axios.get(EVENTLOGS_LOG_NAMES_ENDPOINT, { headers });
  return data;
}

export async function getEventlogsReportNames(headers) {
  const { data } = await axios.get(EVENTLOGS_REPORT_NAMES_ENDPOINT, { headers });
  return data;
}

export async function postErrorEvent(body, headers) {
  const { data } = await axios.post(ERROR_REPORTING_ENDPOINT, body, { headers });
  return data;
}

export async function postLogEvent(body, headers) {
  const { data } = await axios.post(LOG_REPORTING_ENDPOINT, body, { headers });
  return data;
}
